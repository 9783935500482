import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';


export const ProductDetail = () => {
  const navigate = useNavigate();

  const handleDashboardClick = () => {
    navigate('/')
  };

  const handleProductClick = () => {
    navigate('/product')
  };
  
  const handlePackingClick = () => {
    navigate('/packing')
  };

  const columns = [
    { field: 'id', headerName: '資材番号', width: 150 },
    {
      field: 'materialName',
      headerName: '資材名',
      width: 300,
      editable: true,
    },
    {
      field: 'amount',
      headerName: '必要数量',
      width: 300,
      editable: true,
    },
  ];
  
  const rows = [
    { id: 1, materialName: '包装紙A', amount: '6', age: 35 },
    { id: 2, materialName: '紙箱A', amount: '1', age: 16 },
    { id: 3, materialName: 'フィルムA', amount: '1', age: 44 },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{'backgroundColor': "#02AB5E"}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            梱包資材管理システム
          </Typography>
          <Stack direction={"row"}>
            <Button onClick={handleDashboardClick} style={{'color': 'white'}}>ダッシュボード</Button>
            <Button onClick={handleProductClick} style={{'color': 'white'}}>製品</Button>
            <Button onClick={handlePackingClick} style={{'color': 'white'}}>梱包資材</Button>
          </Stack>
          <img src="/tsukasa_logo.png" width="130" alt="ロゴ画像" />
        </Toolbar>
      </AppBar>
      <Box margin={3}>
      <Grid container md={12} spacing={2}>
        <Grid container item md={12}>
          <Grid item md={3}>
          <Typography variant='h4'>製品詳細</Typography>
          </Grid>
          <Grid item md={9}>
            <Typography variant='h6'>製品番号: 1</Typography>
            <Typography variant='h6'>製品名: 6個入どら焼き</Typography>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={2}>
          <TextField label="資材番号"></TextField>
          <TextField label="資材名"></TextField>
          <Button variant='contained'><SearchIcon />検索</Button>
          </Stack>
        </Grid>
        <Grid item md={9}>
      <DataGrid
      style={{'width': 800}}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 30,
            },
          },
        }}
        pageSizeOptions={[30]}
        disableRowSelectionOnClick
      />
      </Grid>
      </Grid>
      </Box>
    </Box>
  );
}
