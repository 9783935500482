import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';


export const Product = () => {
  const navigate = useNavigate();

  const handleDashboardClick = () => {
    navigate('/')
  };

  const handleProductClick = () => {
    navigate('/product')
  };
  
  const handlePackingClick = () => {
    navigate('/packing')
  };
  
  const handleColumnClick = () => {
    navigate('/product_detail')
  };

  const columns = [
    { field: 'id', headerName: '製品番号', width: 150 },
    {
      field: 'productName',
      headerName: '製品名',
      width: 300,
      editable: true,
    },
  ];
  
  const rows = [
    { id: 1, productName: '6個入どら焼き', firstName: 'Jon', age: 35 },
    { id: 2, productName: 'ソフトワッフル', firstName: 'Cersei', age: 42 },
    { id: 3, productName: 'アップルパイ', firstName: 'Jaime', age: 45 },
    { id: 4, productName: 'バームクーヘン', firstName: 'Arya', age: 16 },
    { id: 5, productName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, productName: 'チョコレートケーキ', firstName: null, age: 150 },
    { id: 7, productName: 'バナナケーキ', firstName: 'Ferrara', age: 44 },
    { id: 8, productName: 'パイナップルケーキ', firstName: 'Rossini', age: 36 },
    { id: 9, productName: 'パンケーキ', firstName: 'Harvey', age: 65 },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{'backgroundColor': "#02AB5E"}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            梱包資材管理システム
          </Typography>
          <Stack direction={"row"}>
            <Button onClick={handleDashboardClick} style={{'color': 'white'}}>ダッシュボード</Button>
            <Button onClick={handleProductClick} style={{'color': 'white'}}>製品</Button>
            <Button onClick={handlePackingClick} style={{'color': 'white'}}>梱包資材</Button>
          </Stack>
          <img src="/tsukasa_logo.png" width="130" alt="ロゴ画像" />
        </Toolbar>
      </AppBar>
      <Box margin={3}>
      <Grid container md={12} spacing={2}>
        <Grid item md={12}>
          <Typography variant='h4'>製品一覧</Typography>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={2}>
          <TextField label="製品番号"></TextField>
          <TextField label="製品名"></TextField>
          <Button variant='contained'><SearchIcon />検索</Button>
          </Stack>
        </Grid>
        <Grid item md={9}>
      <DataGrid
      style={{'width': 800}}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 30,
            },
          },
        }}
        pageSizeOptions={[30]}
        disableRowSelectionOnClick
        onCellDoubleClick={handleColumnClick}
      />
      </Grid>
      </Grid>
      </Box>
    </Box>
  );
}
