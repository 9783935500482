import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import React, { useRef, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title
);

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


const data2 = {
  labels: ["10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00"],
  datasets: [
    {
      type: "line", // 折れ線
      label: "生産予定",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      fill: false,
      data: [20, 30, 40, 50, 60, 70, 80],
      yAxisID: "y" // optionsで設定したIDを割り振ってY軸を設定する
    },
    {
      type: "line", // 折れ線
      label: "生産状況",
      borderColor: "rgb(53, 162, 235)",
      borderWidth: 2,
      fill: false,
      data: [18, 26, 46, 52, 55],
      yAxisID: "y" // optionsで設定したIDを割り振ってY軸を設定する
    },
  ]
};

export const options2 = {
  plugins: {
    title: {
      display: true,
      text: "本日の生産進捗状況",
      font: {
        size: 20,
        weight: 'normal'
      }
    },
    legend: { // 凡例の設定
      position: "bottom" // 下に配置
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: false
    },
    y: { // Y軸が複数あるのでyとy1のように軸にIDを付ける
      stacked: false,
      max: 100,
      min: 0
    }
  }
};

const data1 = {
  labels: ["4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月", "1月", "2月", "3月"],
  datasets: [
    {
      type: "line", // 折れ線
      label: "生産計画",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      fill: false,
      data: [10, 30, 40, 20, 30, 40, 20],
      yAxisID: "y" // optionsで設定したIDを割り振ってY軸を設定する
    },
    {
      type: "bar", // 棒グラフ
      label: "在庫数",
      backgroundColor: "rgb(53, 162, 235)",
      data: [21, 33, 44, 32, 63, 51, 42],
      yAxisID: "y" // Y軸の設定
    }
  ]
};

export const options1 = {
  plugins: {
    title: {
      display: true,
      text: "今年度の在庫推移",
      font: {
        size: 20,
        weight: 'normal'
      }
    },
    legend: { // 凡例の設定
      position: "bottom" // 下に配置
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: false
    },
    y: { // Y軸が複数あるのでyとy1のように軸にIDを付ける
      stacked: false,
      max: 100,
      min: 0
    }
  }
};

export const Dashboard = () => {
  const navigate = useNavigate();

  const handleDashboardClick = () => {
    navigate('/')
  };

  const handleProductClick = () => {
    navigate('/product')
  };
  
  const handlePackingClick = () => {
    navigate('/packing')
  };

  
  const columns = [
    {
      field: 'materialName',
      headerName: '梱包資材名',
      width: 150,
      editable: true,
    },
    {
      field: 'stock',
      headerName: '在庫数量',
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <Typography variant={params.row.stock_status === '不足' ? "h5" : "subtitle1"} color={params.row.stock_status === '不足' ? "red" : "black"}>{params.value}</Typography>;
      }
    },
    {
      field: 'required_stock',
      headerName: '必要在庫数',
      width: 150,
      editable: true,
    },
    {
      field: 'stock_status',
      headerName: '在庫状況',
      width: 100,
      editable: true,
      renderCell: (params) => {
        return <Chip label={params.value} variant="filled" color={params.value === '不足' ? "error" : "info"} />;
      }
    }
  ];
  
  const rows = [
    { id: 3, materialName: '包装紙C', stock: '2300', stock_status: '不足', required_stock: 2500, order_status: '発注済み' },
    { id: 1, materialName: '包装紙A', stock: '10000', stock_status: '十分', required_stock: 3000, order_status: null },
    { id: 2, materialName: '包装紙B', stock: '20000', stock_status: '十分', required_stock: 5000, order_status: null },
    { id: 4, materialName: '紙箱A', stock: '500', stock_status: '十分', required_stock: 100, order_status: null },
    { id: 5, materialName: '紙箱B', stock: '600', stock_status: '十分', required_stock: 200, order_status: null },
    { id: 6, materialName: '紙箱C', stock: '260', stock_status: '十分', required_stock: 80, order_status: null },
    { id: 7, materialName: 'フィルムA', stock: '800', stock_status: '十分', required_stock: 300, order_status: null },
    { id: 8, materialName: 'フィルムB', stock: '450', stock_status: '十分', required_stock: 120, order_status: null },
    { id: 9, materialName: 'フィルムC', stock: '260', stock_status: '十分', required_stock: 60, order_status: null },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{'backgroundColor': "#02AB5E"}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            梱包資材管理システム
          </Typography>
          <Stack direction={"row"}>
            <Button onClick={handleDashboardClick} style={{'color': 'white'}}>ダッシュボード</Button>
            <Button onClick={handleProductClick} style={{'color': 'white'}}>製品</Button>
            <Button onClick={handlePackingClick} style={{'color': 'white'}}>梱包資材</Button>
          </Stack>
          <img src="/tsukasa_logo.png" width="130" alt="ロゴ画像" />
        </Toolbar>
      </AppBar>
      <Box margin={3}>
      <Grid container spacing={5}>
        <Grid item md={8} maxHeight={500}>
        <div style={{ width: '100%', height: '100%' }}>
          <Chart type={"bar"} data={data2} options={options2}/>
          </div>
        </Grid>
        <Grid item md={4} maxHeight={500}>
          <Stack height={"100%"}>
          <Typography variant='h6' marginBottom={0.8} marginTop={0.5} textAlign={'center'}>資材在庫情報</Typography>
          <DataGrid
            // style={{'height': '100%'}}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 30,
                },
              },
            }}
            pageSizeOptions={[30]}
            disableRowSelectionOnClick
            hideFooter={true}
            />
          </Stack>
        </Grid>
        
        <Grid item md={8} height={500}>
          <div style={{ width: '100%', height: '100%' }}>
            <Chart type={"bar"} data={data1} options={options1}/>
          </div>
        </Grid>
        <Grid item md={4} height={500}>
          <Stack height={"100%"}>
          <Typography variant='h6' marginBottom={0.8} marginTop={0.5} textAlign={'center'}>アラートメッセージ</Typography>
          <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                      key={0}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{"backgroundColor": "rgb(253, 237, 237)"}}
                    >
                      <TableCell component="th" scope="row" width={30}>
                        <ErrorIcon color='error'/>
                      </TableCell>
                      <TableCell width={200}>包装紙Cの在庫が不足しています。</TableCell>
                      <TableCell width={50}>2023/10/6 12:32:36</TableCell>
                    </TableRow>
                  <TableRow
                      key={0}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{"backgroundColor": "rgb(255, 244, 229)"}}
                    >
                      <TableCell component="th" scope="row" width={30}>
                        <WarningIcon color='warning'/>
                      </TableCell>
                      <TableCell width={200}>生産が計画より遅れています。</TableCell>
                      <TableCell width={50}>2023/10/6 12:00:00</TableCell>
                    </TableRow>
                  <TableRow
                      key={0}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" width={30}>
                        <CheckCircleIcon color='success'/>
                      </TableCell>
                      <TableCell width={200}>紙箱Aの在庫が補充されました。</TableCell>
                      <TableCell width={50}>2023/10/6 11:51:20</TableCell>
                    </TableRow>
                  <TableRow
                      key={0}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{"backgroundColor": "rgb(253, 237, 237)"}}
                    >
                      <TableCell component="th" scope="row" width={30}>
                        <ErrorIcon color='error'/>
                      </TableCell>
                      <TableCell width={200}>紙箱Aの在庫が不足しています。</TableCell>
                      <TableCell width={50}>2023/10/5 16:32:12</TableCell>
                    </TableRow>
                  <TableRow
                      key={0}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" width={30}>
                        <CheckCircleIcon color='success'/>
                      </TableCell>
                      <TableCell width={200}>フィルムBの在庫が補充されました。</TableCell>
                      <TableCell width={50}>2023/10/5 13:14:50</TableCell>
                    </TableRow>
                  <TableRow
                      key={0}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" width={30}>
                        <CheckCircleIcon color='success'/>
                      </TableCell>
                      <TableCell width={200}>生産が順調に進んでいます。</TableCell>
                      <TableCell width={50}>2023/10/5 12:36:00</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
