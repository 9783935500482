import './App.css';
import { HashRouter, Route, Routes, Link } from 'react-router-dom';
import { Product } from './pages/product';
import { Packing } from './pages/packing';
import { ProductDetail } from './pages/product_detail';
import { Dashboard } from './pages/dashboard';

export const App = () => {
  return (
    <>
    <HashRouter>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/product' element={<Product />} />
        <Route path='/packing' element={<Packing />} />
        <Route path='/product_detail' element={<ProductDetail />} />
      </Routes>
    </HashRouter>
    </>
  );
}
