import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';


export const Packing = () => {
  const navigate = useNavigate();
  const [is_clicked, setIsClicked] = useState(false);

  const handleDashboardClick = () => {
    navigate('/')
  };

  const handleProductClick = () => {
    navigate('/product')
  };
  
  const handlePackingClick = () => {
    navigate('/packing')
  };

  const handleSwitchClick = () => {
    
  }

  const columns = [
    { field: 'id', headerName: '梱包資材番号', width: 130 },
    {
      field: 'materialName',
      headerName: '梱包資材名',
      width: 200,
      editable: true,
    },
    {
      field: 'stock',
      headerName: '在庫数量',
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <Typography variant={params.row.stock_status === '不足' ? "h5" : "subtitle1"} color={params.row.stock_status === '不足' ? "red" : "black"}>{params.value}</Typography>;
      }
    },
    {
      field: 'required_stock',
      headerName: '必要在庫数（来月）',
      width: 150,
      editable: true,
    },
    {
      field: 'stock_status',
      headerName: '在庫状況',
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <Chip label={params.value} variant="filled" color={params.value === '不足' ? "error" : "info"} />;
      }
    }
  ];
  
  const rows = [
    { id: 1, materialName: '包装紙A', stock: '10000', stock_status: '十分', required_stock: 3000, order_status: null },
    { id: 2, materialName: '包装紙B', stock: '20000', stock_status: '十分', required_stock: 5000, order_status: null },
    { id: 3, materialName: '包装紙C', stock: '2300', stock_status: '不足', required_stock: 2500, order_status: '発注済み' },
    { id: 4, materialName: '紙箱A', stock: '500', stock_status: '十分', required_stock: 100, order_status: null },
    { id: 5, materialName: '紙箱B', stock: '600', stock_status: '十分', required_stock: 200, order_status: null },
    { id: 6, materialName: '紙箱C', stock: '260', stock_status: '十分', required_stock: 80, order_status: null },
    { id: 7, materialName: 'フィルムA', stock: '800', stock_status: '十分', required_stock: 300, order_status: null },
    { id: 8, materialName: 'フィルムB', stock: '450', stock_status: '十分', required_stock: 120, order_status: null },
    { id: 9, materialName: 'フィルムC', stock: '260', stock_status: '十分', required_stock: 60, order_status: null },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{'backgroundColor': "#02AB5E"}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            梱包資材管理システム
          </Typography>
          <Stack direction={"row"}>
            <Button onClick={handleDashboardClick} style={{'color': 'white'}}>ダッシュボード</Button>
            <Button onClick={handleProductClick} style={{'color': 'white'}}>製品</Button>
            <Button onClick={handlePackingClick} style={{'color': 'white'}}>梱包資材</Button>
          </Stack>
          <img src="/tsukasa_logo.png" width="130" alt="ロゴ画像" />
        </Toolbar>
      </AppBar>
      <Box margin={3}>
      <Grid container md={12} spacing={2}>
        <Grid item md={3}>
          <Typography variant='h4'>梱包資材一覧</Typography>
        </Grid>
        <Grid item md={9}>
        {/* <FormGroup>
          <Stack direction={"row"}>
          <FormControlLabel control={<Switch onClick={handleSwitchClick} checked={is_clicked} />} label="不足のみ表示" /> */}
          <Button variant='contained'>CSV出力</Button>
          {/* </Stack> */}
        {/* </FormGroup> */}
        </Grid>
        <Grid item md={3}>
          <Stack spacing={2}>
          <TextField label="資材番号"></TextField>
          <TextField label="資材名"></TextField>
          <Button variant='contained'><SearchIcon />検索</Button>
          </Stack>
        </Grid>
        <Grid item md={9}>
      <DataGrid
      style={{'width': 800}}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 30,
            },
          },
        }}
        pageSizeOptions={[30]}
        disableRowSelectionOnClick
      />
      </Grid>
      </Grid>
      </Box>
    </Box>
  );
}
